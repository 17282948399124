<template>
  <Table :pagination="pagination">
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
          {{ $t(header) }}
        </template>
      </TableElementItem>
    </template>
    <template v-if="!elements.isEmpty()">
      <TableElement :class="element.getId() % 2 == 0 ? 'even': 'odd'" v-for="element in elements.getItems()"
                    v-bind:key="element.getId()">
        <TableElementItem class="col-5">
          <template v-slot:default>
            {{ element.getText() }}
          </template>
        </TableElementItem>
        <TableElementItem class="col-2">
          <template v-slot:default>
            {{ formatDateTime(element.getCreatedAt()) }}
          </template>
        </TableElementItem>
      </TableElement>
    </template>
    <template v-slot:footer v-if="elements.isEmpty()" >
      <TableElement :size="headers.length" :empty-message="$t('notifications.list.empty')" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/elements/Table";
import {DatesMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";

export default {
  name: "NotificationTable",
  mixins: [ScreenSizeMixin, DatesMixin],
  components: {TableElement, TableElementItem, Table},
  props: {
    elements: Object
  },
  data() {
    return {
      allHeaders: [
        'notifications.list.text',
        'notifications.list.created_at',
      ],
      responsiveHeaders: [
        'notifications.list.text',
        'notifications.list.created_at',
      ],
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    },
    pagination: function () {
      return this.elements.getPagination();
    }
  },
}
</script>

<style scoped>

</style>