<template>
  <Card :title="$t('notifications.list.title')" :with-footer="false">
    <template v-slot:default>
      <NotificationTable @page="getNotifications" :elements="notifications" />
    </template>
  </Card>
</template>

<script>
import Card from "@/components/elements/Card";
import NotificationTable from "@/components/notification/NotificationTable";
import LoadableCollection from "@/entities/LoadableCollection";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import Pagination from "@/entities/Pagination";
import Notification from "@/entities/Notification";

export default {
  name: "NotificationList",
  components: {Card, NotificationTable},
  mixins: [LoadingModalMixin],
  data: function () {
    return {
      notifications: new LoadableCollection(),
    }
  },
  beforeMount() {
    this.getNotifications();
  },
  methods: {
    getNotifications: function (page = 1) {
      let that = this;
      let filterParams = {
        page: page,
      };

      this.queueJob();
      this.notifications.unloadAndEmpty();
      this.axios.get(this.$store.state.config.getNotificationsUri(), {params: filterParams}).then(
          response => {
            let content = response.data;
            that.notifications.setPagination(
                new Pagination(
                    content.data.current_page,
                    content.data.total,
                    content.data.per_page,
                    content.data.last_page
                )
            );

            content.data.data.forEach(function (notification) {
              that.notifications.push(new Notification(notification));
            });

            that.notifications.loaded();
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
  }
}
</script>

<style scoped>

</style>